import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './features/auth/auth.slice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { appSlice } from '@store/features/app/app.slice';
import { jobSlice } from './features/move/jobs.slice';
import { authApi } from './features/auth/auth.api';
import { moveApi } from './features/move/move.api';
import { profileApi } from './features/profile/profile.api';
import { cardApi } from '@store/features/card/card.api';
import { appApi } from '@store/features/app/app.api';
import { subscriptionApi } from '@store/features/subscription/subscription.api';
import { serviceApi } from './features/service-area/service-area.api';

export const store = configureStore({
  reducer: {
    [jobSlice.name]: jobSlice.reducer,
    [appSlice.name]: appSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [moveApi.reducerPath]: moveApi.reducer,
    [cardApi.reducerPath]: cardApi.reducer,
    [appApi.reducerPath]: appApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [serviceApi.reducerPath]: serviceApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      moveApi.middleware,
      profileApi.middleware,
      cardApi.middleware,
      appApi.middleware,
      subscriptionApi.middleware,
      serviceApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
