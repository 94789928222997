import { PHONE_REGEX } from '@components/PhoneField';
import * as yup from 'yup';

const charMessage = 'The field must contain at least 1 character'

export const ContactUsFormSchema = yup.object().shape({
  name: yup
    .string()
    .trim('The field cannot include trailing spaces')
    .strict(true)
    .min(1, charMessage)
    .required(),
  email: yup
    .string()
    .email()
    .required(),
  phone: yup
    .string()

    .matches(PHONE_REGEX, 'Incorrect phone format')
    .required(),
  message: yup
    .string()
    .trim('The field cannot include trailing spaces')
    .strict(true)
    .min(1, charMessage)
    .required(),
});
