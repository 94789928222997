import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import COLORS from '@utils/colors';
import { Button } from '@mui/material';


export const StyledModal = styled(Modal)(({ theme }) => ({
  '.MuiPaper-root': {
    width: '100%',
    borderRadius: 20
  },

  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,

    '.MuiPaper-root': {
      maxWidth: 'unset',
      margin: 0,
      borderRadius: 0,
      height: '100vh',
      maxHeight: '100%'
    }
  }
}));

export const StyledDialogContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '100%',
  maxWidth: "792px",
  height: '453px',
  borderRadius: '32px',
  transform: 'translate(-50%, -50%)',
  background: 'white',
  border: '1px solid silver',
  padding: '79px 30px 49px 30px',

  [theme.breakpoints.down('sm')]: {
  }
}));

export const Content = styled(Box)(({ theme }) => ({
  padding: '0',
  margin: '23px auto 40px auto',
  maxWidth: '580px',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    padding: '0',
  }
}));


export const StyledModalTitle = styled(Typography)(({ theme }) => ({
  fontSize: '39.77px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '130%',
  letterSpacing: '-1.591px',

  [theme.breakpoints.down('sm')]: {
    padding: '0px',
  }
}));

export const StyledTypography = styled(Typography)(() => ({
  color: "#282828",
  marginBottom: '10px',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '21.848px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '130%',
  letterSpacing: "-0.874px",
}));

export const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  zIndex: 999,
  right: 41,
  top: 41,
  color: COLORS.darkBlue,

  svg: {
    width: 18,
    height: 18
  }
}));

export const Buttons = styled(Box)(() => ({
  display: 'flex',
  gap: '21px',
  justifyContent: 'center'
}));

export const StyledButton = styled(Button)(() => ({
  height: '45px',
  width: '100%',
  maxWidth: '492px',
  fontSize: "19px",

  '&.MuiButton-contained': {
    background: '#A6E6A9',
  },
  '&.MuiButton-outlined': {
    background: 'transparent',
    border: '1px solid #282750',
    color: '#162913'
  }
}));

