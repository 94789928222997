import DialogTitle from '@mui/material/DialogTitle';
import { ReactComponent as CloseIcon } from '@assets/icons/popup-close-icon.svg';
import {
  StyledDialogContent,
  StyledModal,
  StyledModalTitle,
  StyledTypography,
  Content,
  Buttons,
  StyledButton,
  StyledIconButton,
} from './styled';

interface MaximumCubicFeetProps {
  isOpen: boolean;
  onClose: () => void;
}

const MaximumCubicFeet = ({ isOpen, onClose }: MaximumCubicFeetProps) => {
  return (
    <StyledModal
      keepMounted
      open={isOpen}
    >
      <StyledDialogContent>
        <DialogTitle sx={{ padding: 0 }}>
          <StyledIconButton
            aria-label="close"
            onTouchMove={onClose}
            onClick={onClose}
          >
            <CloseIcon />
          </StyledIconButton>
        </DialogTitle>
        <StyledModalTitle variant="h1" textAlign="center">
          Maximum Cubic Feet
        </StyledModalTitle>
        <Content>
          <StyledTypography
            variant="subtitle1"
          >
            We have estimated the total amount of cubic feet for each move based on house size. The quote you submit is for up to the maximum cubic feet listed in the job description. If a move ends up being over the maximum amount of cubic feet listed, the customer will pay the dollar amount per cubic foot that you included in your quote for “Overage”.
          </StyledTypography>
        </Content>
        <Buttons>
          <StyledButton
            variant="contained"
            onClick={onClose}
          >
            Dismiss
          </StyledButton>
        </Buttons>
      </StyledDialogContent>
    </StyledModal>
  );
};

export default MaximumCubicFeet;
