import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import COLORS from '@utils/colors';
import { Button } from '@mui/material';

export const StyledModal = styled(Modal)(({ theme }) => ({
  '.MuiPaper-root': {
    width: '100%',
    borderRadius: 20,
  },

  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,

    '.MuiPaper-root': {
      maxWidth: 'unset',
      margin: 0,
      borderRadius: 0,
      height: '100vh',
      maxHeight: '100%',
    },
  },
}));

export const StyledDialogContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '100%',
  maxWidth: '825px',
  transform: 'translate(-50%, -50%)',
  background: 'white',
  border: '1px solid silver',
  borderRadius: '10px',
  padding: '36px 30px 56px 30px',

  [theme.breakpoints.down('sm')]: {
    overflow: 'scroll',
    height: '100vh',
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  padding: '0px 60px',
  margin: '39px auto',
  maxWidth: '652px',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    padding: '0',
  },
}));

export const StyledModalTitle = styled(Typography)(({ theme }) => ({
  fontSize: '39.77px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '130%',
  letterSpacing: '-1.591px',

  [theme.breakpoints.down('sm')]: {
    padding: '0px',
  },
}));

export const StyledTypography = styled(Typography)(() => ({
  color: '#131929',
  marginBottom: '10px',
}));

export const StyledTextField = styled(TextField)(() => ({
  width: 300,
  marginTop: 15,
}));

export const StyledTextArea = styled('textarea')(({ theme }) => ({
  width: 300,
  border: `1px solid rgba(0, 0, 0, 0.23)`,
  borderRadius: 14,
  padding: '12px 15px 108px 15px',
  fontFamily: 'Plain-Light',
  fontSize: 16,
  marginTop: 15,

  '&::placeholder': {
    opacity: 0.5,
    color: COLORS.darkBlue,
  },

  [theme.breakpoints.down('sm')]: {
    paddingBottom: 176,
    width: '256px',
  },
}));

export const StyledLeftLogo = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const StyledRightLogo = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  zIndex: 999,
  right: 41,
  top: 41,
  color: COLORS.darkBlue,

  svg: {
    width: 18,
    height: 18,
  },
}));

export const StyledSuccessMessage = styled(Typography)(() => ({
  color: COLORS.morningGlory,
  fontSize: '17px',
  margin: 25,
}));

export const Buttons = styled(Box)(() => ({
  display: 'flex',
  gap: '21px',
  justifyContent: 'center',
}));

export const StyledButton = styled(Button)(() => ({
  height: '54px',
  width: '190px',

  '&.MuiButton-contained': {
    background: '#A6E6A9',
  },
  '&.MuiButton-outlined': {
    background: 'transparent',
    border: '1px solid #282750',
    color: '#162913',
  },
  '&:hover': {
    background: 'rgb(255, 180, 158)',
  },
}));
