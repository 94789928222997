import { useDispatch } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { useAppSelector } from '@store/index';
import { ToastContainer } from 'react-toastify';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ContactUsPopup from '@components/popups/ContactUsPopup';
import WelcomeJobBoardPopup from '@components/popups/WelcomeJobBoardPopup';
import { setIsContactUsPopupOpen,  } from '@store/features/app/app.slice';
import router from 'src/router';
import ThemeProvider from './theme/ThemeProvider';

function App() {
  const content = useRoutes(router);
  const dispatch = useDispatch();
  const { isContactUsPopupOpen, isWelcomeBoardPopupOpen } = useAppSelector((state) => state.app);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <ToastContainer />
        {content}

        <ContactUsPopup
          isOpen={isContactUsPopupOpen}
          onClose={() => dispatch(setIsContactUsPopupOpen(false))}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
