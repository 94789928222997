import { useState } from 'react';

import {
  StyledDialogContent,
  StyledModal,
  StyledModalTitle,
  StyledTypography,
  Content,
  Buttons,
  StyledButton,
} from './styled';
import { useContactUsMutation } from '@store/features/app/app.api';
import { setIsContactUsPopupOpen } from '@store/features/app/app.slice';
import { useAppDispatch } from '@store/index';

interface WelcomeJobBoardPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const WelcomeJobBoardPopup = ({
  isOpen,
  onClose,
}: WelcomeJobBoardPopupProps) => {
  const dispatch = useAppDispatch();

  return (
    <StyledModal keepMounted open={isOpen}>
      <StyledDialogContent>
        <StyledModalTitle variant="h1" textAlign="center">
          Welcome to the Moo Moves You <br />
          Moo’ving Partner Portal!
        </StyledModalTitle>
        <Content>
          <StyledTypography variant="subtitle1">
            <b>Create Your Service Area(s): </b> Head over to the Service Areas
            tab and let us know which types of leads you want to receive. Create
            different Service Areas for in-state and interstate jobs. You get
            instantly connected with prospective customers that have pickup
            addresses that match the Service Area(s) you create!
          </StyledTypography>
          <StyledTypography variant="subtitle1">
            <b>Check Out The Lead Board:</b> Do you ever have an empty backhaul
            or looking for work outside of your normal Service Area(s)? Go to
            our Lead Board, sort through all of the available leads based on
            pickup or dropoff address, move date or job type, and purchase the
            leads a la carte.
          </StyledTypography>
          <StyledTypography variant="subtitle1">
            If you have any questions or need assistance, our Support Team is
            always available to help! Send us an email at
            Support@MooMovesYou.com or give us a call at (561) 706-1285.
          </StyledTypography>
          <StyledTypography variant="subtitle1">
            Let’s Get Moo’ving!
          </StyledTypography>
        </Content>
        <Buttons>
          <StyledButton variant="contained" onClick={onClose}>
            I Understand
          </StyledButton>
          <StyledButton
            variant="outlined"
            onClick={() => {
              onClose();
              dispatch(setIsContactUsPopupOpen(true));
            }}>
            Contact Support
          </StyledButton>
        </Buttons>
      </StyledDialogContent>
    </StyledModal>
  );
};

export default WelcomeJobBoardPopup;
