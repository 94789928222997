import { useContext } from 'react';

import { Button, List, ListItem } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import { setIsContactUsPopupOpen } from '@store/features/app/app.slice';
import { useDispatch } from 'react-redux';
import { MenuWrapper, SubMenuWrapper } from './styled';

function SidebarMenu() {
  const dispatch = useDispatch();
  const { closeSidebar } = useContext(SidebarContext);

  return (
    <>
      <MenuWrapper>
        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  to="/service-area"
                  onClick={closeSidebar}
                  component={RouterLink}>
                  Service Areas
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/lead-board">
                  Lead Board
                </Button>
              </ListItem>

              <ListItem component="div">
                <Button
                  disableRipple
                  to="/my-leads"
                  component={RouterLink}
                  onClick={closeSidebar}>
                  My Leads
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/profile">
                  My Profile
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  to="/my-cards"
                  onClick={closeSidebar}
                  component={RouterLink}>
                  My Cards
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  to="/receipts"
                  onClick={closeSidebar}
                  component={RouterLink}>
                  Receipts
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  onClick={() => dispatch(setIsContactUsPopupOpen(true))}>
                  Help
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
