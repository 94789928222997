import { createApi } from '@reduxjs/toolkit/query/react';
import { IContactUsFormValues } from '@components/popups/ContactUsPopup';
import { IStateInfo } from '@store/features/service-area/service-area.types';
import { baseQueryWithReAuth } from '@store/baseQueryWithReAuth';

export const appApi = createApi({
  reducerPath: 'appApi',
  baseQuery: baseQueryWithReAuth(''),
  keepUnusedDataFor: 0,
  endpoints: builder => ({
    contactUs: builder.mutation<void, IContactUsFormValues>({
      query: body => {
        return {
          url: '/questions',
          body,
          method: 'POST',
        };
      },
    }),
    getSettingsByKey: builder.query<any, string>({
      query: key => `/settings/${key}/key`,
    }),
    getHouses: builder.query<any, void>({
      query: () => `/houses`,
    }),
    getStates: builder.query<IStateInfo[], void>({
      query: () => `/states`,
    }),
  }),
});

export const {
  useContactUsMutation,
  useGetSettingsByKeyQuery,
  useLazyGetHousesQuery,
  useLazyGetStatesQuery,
} = appApi;
