import React from "react"

export const PHONE_REGEX = /^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/
import { Controller } from "react-hook-form"

function addPropsToReactElement(element: any, props: any) {
  if (React.isValidElement(element)) {
    return React.cloneElement(element, props)
  }
  return element
}

function addPropsToChildren(children: any, props: any) {
  if (!Array.isArray(children)) {
    return addPropsToReactElement(children, props)
  }
  return children.map(childElement =>
    addPropsToReactElement(childElement, props)
  )
}

const PhoneField = ({ control, children, placeholder = 'Phone Number' }: any) => {
  return (
    <Controller
      name="phone"
      control={control}
      rules={{
        required: {
          value: true,
          message: 'Phone is a required field'
        },
        pattern: {
          value: PHONE_REGEX,
          message: 'Please enter a valid phone.'
        }
      }}
      render={({
        field: {
          onChange,
          onBlur,
          value,
          ref
        },
      }) => (
        addPropsToChildren(children, {
          name: "phone",
          placeholder,
          country: "us",
          onlyCountries: ['us'],
          disableCountryCode: true,
          masks: { us: '(...) ...-....' },
          value: value,
          onBlur: onBlur,
          onChange: onChange,
          inputProps: {
            ref
          }
        })
      )
      }
    />
  )
}

export default PhoneField