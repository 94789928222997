import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { IAppState } from '@store/features/app/app.types';

const initialState: IAppState = {
  isContactUsPopupOpen: false,
  isWelcomeBoardPopupOpen: false,
  isPlaceBidPopupOpen: false,
  isHaveBeenConnectedPopupOpen: false,
  isMaxCubFitPopupOpen: false,
  isQuotedBidInfoPopupOpen: false
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsContactUsPopupOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isContactUsPopupOpen = payload;
    },
    setIsWelcomeBoardPopupOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isWelcomeBoardPopupOpen = payload;
    },
    setIsPlaceBidPopupOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPlaceBidPopupOpen = payload;
    },
    setIsQuotedBidInfoPopupOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isQuotedBidInfoPopupOpen = payload;
    },
    setIsHaveBeenConnectedPopupOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isHaveBeenConnectedPopupOpen = payload;
    },
    setIsMaxCubFitPopupOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isMaxCubFitPopupOpen = payload;
    },
  }
});

export const {
  setIsContactUsPopupOpen,
  setIsWelcomeBoardPopupOpen,
  setIsPlaceBidPopupOpen,
  setIsHaveBeenConnectedPopupOpen,
  setIsMaxCubFitPopupOpen,
  setIsQuotedBidInfoPopupOpen
} = appSlice.actions;
