import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useLazyGetProfileQuery } from '@store/features/profile/profile.api';
import { logout, setUser } from '@store/features/auth/auth.slice';

export const useAuthAndNavigation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const [getUser, { isError, data: userInfo, isSuccess }] =
    useLazyGetProfileQuery();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!accessToken) {
      setIsLoading(false);
      dispatch(logout());
      navigate('/login');
    } else {
      getUser().finally(() => setIsLoading(false));
    }
  }, [accessToken]);

  useEffect(() => {
    if (isError || isSuccess) {
      setIsLoading(false);
      if (isError) {
        dispatch(logout());
        if (location.pathname !== '/profile') {
          navigate('/login');
        }
      } else if (userInfo) {
        dispatch(setUser(userInfo));
        if (location.pathname !== '/profile') {
          navigate(userInfo.isActive ? '/' : '/activate-account');
        }
      }
    }
  }, [isError, isSuccess, userInfo]);

  return { isLoading };
};
