import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from '@store/baseQuery';
import {
  AddServiceAreaLocationRequest,
  IServiceAreaLocation,
  ServiceAreaConfigResponse,
  ServiceAreaEnum,
  IServiceAreaState,
  IServiceAreaZipCode,
} from '@store/features/service-area/service-area.types';
import { baseQueryWithReAuth } from '@store/baseQueryWithReAuth';

export const serviceApi = createApi({
  reducerPath: 'serviceApi',
  baseQuery: baseQueryWithReAuth('partner/'),
  keepUnusedDataFor: 0,
  endpoints: builder => ({
    getServiceAreaConfig: builder.query<ServiceAreaConfigResponse, void>({
      query: () => `service-areas/config`,
    }),

    getServiceAreaStates: builder.query<
      IServiceAreaState[],
      { type: ServiceAreaEnum }
    >({
      query: ({ type }) => `service-areas/states?type=${type}`,
    }),

    getServiceAreaLocations: builder.query<
      IServiceAreaLocation[],
      { type: ServiceAreaEnum }
    >({
      query: ({ type }) => `service-areas/locations?stateType=${type}`,
    }),

    getServiceAreaZipCodes: builder.query<
      IServiceAreaZipCode[],
      { stateType: string }
    >({
      query: ({ stateType }) =>
        `service-areas/zip-codes?stateType=${stateType}`,
    }),

    createServiceAreaLocation: builder.mutation<
      IServiceAreaLocation,
      AddServiceAreaLocationRequest
    >({
      query: body => ({
        url: `service-areas/locations`,
        method: 'POST',
        body,
      }),
    }),

    createServiceAreaState: builder.mutation<
      IServiceAreaState,
      { stateId: number; type: ServiceAreaEnum }
    >({
      query: body => ({
        url: `service-areas/states`,
        method: 'POST',
        body,
      }),
    }),

    updateServiceAreaConfig: builder.mutation<
      ServiceAreaConfigResponse,
      Partial<ServiceAreaConfigResponse>
    >({
      query: body => ({
        url: `service-areas/config`,
        method: 'PATCH',
        body,
      }),
    }),

    deleteServiceAreaState: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `service-areas/states/${id}`,
        method: 'DELETE',
      }),
    }),

    deleteServiceAreaLocation: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `service-areas/locations/${id}`,
        method: 'DELETE',
      }),
    }),

    deleteServiceAreaZipCode: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `service-areas/zip-codes/${id}`,
        method: 'DELETE',
      }),
    }),

    updateServiceAreaLocation: builder.mutation<
      IServiceAreaLocation,
      { id: number; placeId: string; radius: number }
    >({
      query: ({ id, ...patchData }) => ({
        url: `service-areas/locations/${id}`,
        method: 'PATCH',
        body: patchData,
      }),
    }),

    updateServiceAreaZipCode: builder.mutation<
      IServiceAreaZipCode,
      { code: string; id: number }
    >({
      query: ({ id, ...patchData }) => ({
        url: `service-areas/zip-codes/${id}`,
        method: 'PATCH',
        body: patchData,
      }),
    }),

    updateServiceAreaState: builder.mutation<
      IServiceAreaState,
      { id: number; newStateId: number }
    >({
      query: ({ id, newStateId }) => ({
        url: `service-areas/states/${id}`,
        method: 'PATCH',
        body: { newStateId },
      }),
    }),

    createServiceAreaZipCode: builder.mutation<
      IServiceAreaZipCode,
      { stateType: ServiceAreaEnum; code: string }
    >({
      query: body => ({
        url: `service-areas/zip-codes`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useDeleteServiceAreaZipCodeMutation,
  useUpdateServiceAreaZipCodeMutation,
  useCreateServiceAreaZipCodeMutation,
  useLazyGetServiceAreaZipCodesQuery,
  useUpdateServiceAreaLocationMutation,
  useUpdateServiceAreaStateMutation,
  useDeleteServiceAreaLocationMutation,
  useDeleteServiceAreaStateMutation,
  useLazyGetServiceAreaConfigQuery,
  useGetServiceAreaConfigQuery,
  useLazyGetServiceAreaStatesQuery,
  useUpdateServiceAreaConfigMutation,
  useLazyGetServiceAreaLocationsQuery,
  useCreateServiceAreaLocationMutation,
  useCreateServiceAreaStateMutation,
} = serviceApi;
