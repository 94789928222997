import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const LogoWrapper = styled('div')`
  display: flex;
  text-decoration: none;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
`;
