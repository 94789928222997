import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from '../../baseQuery';
import { UpdateCompanyFormValues } from '../../../content/applications/Users/settings/EditProfileTab/UpdateCompany';
import { UpdateProfileFormValues } from '../../../content/applications/Users/settings/EditProfileTab/UpdateProfile';
import {
  IGetSubscriptionResponse,
  IPartner,
  IPartnerNotificationConfigResponse,
  IPartnerProfileRequestBody,
  IReceipts,
  IResubscribeRequest,
  IUpdateSubscriptionCardRequest,
} from '@store/features/profile/profile.types';
import { baseQueryWithReAuth } from '@store/baseQueryWithReAuth';
import { IProfile } from '@store/features/auth/auth.types';

// TODO: here in future we should remove subscription logic
export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: baseQueryWithReAuth('partner'),
  keepUnusedDataFor: 0,
  tagTypes: ['profile'],
  endpoints: builder => ({
    getProfile: builder.query<IProfile, void>({
      query: () => '/profile',
      providesTags: ['profile'],
    }),
    getPartner: builder.query<IPartner, void>({
      query: () => '/auth/profile/partner',
    }),
    getPartnerReceipts: builder.query<
      { data: IReceipts[]; total: number },
      { page: number; limit: number }
    >({
      query: params => ({
        url: '/transactions',
        params: {
          ...params,
        },
      }),
    }),
    activateAccount: builder.mutation<
      void,
      {
        password: string;
        passwordConfirmation: string;
        paymentMethod: string;
        coupon?: string;
      }
    >({
      query: body => ({
        url: '/profile/activate',
        method: 'PATCH',
        body,
      }),
    }),
    getPartnerSubscription: builder.query<IGetSubscriptionResponse, void>({
      query: () => '/partners/subscription',
    }),
    getPromoCode: builder.query<void, string>({
      query: code => `/coupons/${code}`,
    }),
    cancelSubscription: builder.mutation<void, void>({
      query: () => {
        return {
          url: '/partners/subscription',
          method: 'DELETE',
        };
      },
    }),
    resubscribe: builder.mutation<void, IResubscribeRequest>({
      query: body => {
        return {
          url: '/partners/subscription',
          body,
          method: 'POST',
        };
      },
    }),
    updateSubscriptionCard: builder.mutation<
      void,
      IUpdateSubscriptionCardRequest
    >({
      query: body => {
        return {
          url: '/cards/subscription',
          body,
          method: 'put',
        };
      },
    }),
    updateProfile: builder.mutation<void, UpdateProfileFormValues>({
      query: body => {
        return {
          url: 'auth/profile',
          body,
          method: 'PUT',
        };
      },
    }),
    updateCompany: builder.mutation<
      void,
      {
        id: string;
        data: UpdateCompanyFormValues;
      }
    >({
      query: ({ id, data }) => {
        return {
          url: `/partners/${id}`,
          body: data,
          method: 'PUT',
        };
      },
    }),
    partnerUpdatePassword: builder.mutation<
      void,
      {
        newPassword: string;
      }
    >({
      query: ({ newPassword }) => ({
        url: '/profile/password',
        method: 'PATCH',
        body: { newPassword },
      }),
    }),
    getPartnerNotificationConfig: builder.query<
      IPartnerNotificationConfigResponse,
      void
    >({
      query: () => '/notification/config',
    }),
    updatePartnerNotificationConfig: builder.mutation<
      void,
      { email: string; phone: string; isPhoneActive: boolean }
    >({
      query: body => ({
        url: '/notification/config',
        method: 'PATCH',
        body,
      }),
    }),
    updatePartnerProfile: builder.mutation<
      void,
      Partial<IPartnerProfileRequestBody>
    >({
      query: profile => {
        return {
          url: '/profile',
          method: 'PATCH',
          body: profile,
        };
      },
    }),
  }),
});

export const {
  useGetProfileQuery,
  useLazyGetProfileQuery,
  useUpdatePartnerProfileMutation,
  useLazyGetPartnerNotificationConfigQuery,
  useUpdatePartnerNotificationConfigMutation,
  usePartnerUpdatePasswordMutation,
  useLazyGetPartnerReceiptsQuery,
  useLazyGetPromoCodeQuery,
  useGetPartnerQuery,
  useGetPartnerSubscriptionQuery,
  useLazyGetPartnerSubscriptionQuery,
  useCancelSubscriptionMutation,
  useUpdateSubscriptionCardMutation,
  useResubscribeMutation,
  useUpdateProfileMutation,
  useUpdateCompanyMutation,
  useActivateAccountMutation,
} = profileApi;
