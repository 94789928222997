import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import COLORS from '@utils/colors';
import MuiPhoneNumber from 'react-phone-input-2';

export const StyledModal = styled(Modal)(({ theme }) => ({
  '.MuiPaper-root': {
    width: '100%',
    maxWidth: 670,
    borderRadius: 20
  },

  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,

    '.MuiPaper-root': {
      maxWidth: 'unset',
      margin: 0,
      borderRadius: 0,
      height: '100vh',
      maxHeight: '100%'
    }
  }
}));

export const StyledDialogContent = styled(Box)(() => ({
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: 'white',
  maxWidth: 700,
  border: '1px solid silver',
  borderRadius: '10px',
  padding: 60
}));

export const StyledModalTitle = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 40
}));

export const StyledTextField = styled(TextField)(() => ({
  width: 300,
  marginTop: 15,

  'fieldset': {
    '&.MuiOutlinedInput-notchedOutline': {
      border: '1px solid rgba(0, 0, 0, 0.23) !important',
    }
  },

  'input:-webkit-autofill:active': {
    '-webkit-box-shadow': '0 0 0 30px white inset !important',
  },
  'input:-webkit-autofill:focus': {
    '-webkit-box-shadow': '0 0 0 30px white inset !important',
  },
  'input:-webkit-autofill': {
    '-webkit-box-shadow': '0 0 0 30px white inset !important',
  },
  'input:-webkit-autofill:hover': {
    '-webkit-box-shadow': '0 0 0 30px white inset !important',
  }
}));

export const StyledTextArea = styled('textarea')(({ theme }) => ({
  width: 300,
  border: `1px solid rgba(0, 0, 0, 0.23)`,
  borderRadius: 14,
  padding: '12px 15px 108px 15px',
  fontFamily: 'Plain-Light',
  fontSize: 16,
  marginTop: 15,

  ':focus': {
    outline: 'none !important',
  },
  '&::placeholder': {
    opacity: 0.5,
    color: COLORS.darkBlue
  },

  [theme.breakpoints.down('sm')]: {
    paddingBottom: 176,
    width: '256px'
  }
}));

export const StyledLeftLogo = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,

  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));

export const StyledRightLogo = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,

  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));

export const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  zIndex: 999,
  right: 41,
  top: 41,
  color: COLORS.darkBlue,

  svg: {
    width: 18,
    height: 18
  }
}));

export const StyledSuccessMessage = styled(Typography)(() => ({
  color: COLORS.morningGlory,
  fontSize: '17px',
  margin: 25
}));

export const StyledPhoneNumber = styled(MuiPhoneNumber)(({ theme }) => ({
  padding: '14px',
  maxWidth: '300px',
  width: '100%',
  height: '53px',
  borderRadius: 10,
  marginTop: '15px',
  border: '1px solid rgba(0, 0, 0, 0.23)',

  input: {
    width: '100%',
    maxWidth: '300px',
    fontSize: '14px',
    border: 'none',
    background: 'transparent',
    padding: '0 0 14px 0',
    color: COLORS.darkBlue,
    '&:-webkit-autofill:active': {
      '-webkit-box-shadow': '0 0 0 30px white inset !important',
    },
    '&:-webkit-autofill:focus': {
      '-webkit-box-shadow': '0 0 0 30px white inset !important',
    },
    '&:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 30px white inset !important',
    },
    '&:-webkit-autofill:hover': {
      '-webkit-box-shadow': '0 0 0 30px white inset !important',
    }
  },
  '.form-control::placeholder': {
    color: COLORS.darkBlue,
    opacity: 0.5,
  },
  '.form-control:focus-visible': {
    outlineWidth: 0,
  },
  '& .special-label': {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: 30,
    width: '100%',
    input: {
      fontSize: '14px',
      textAlign: 'left',
      paddingLeft: 0,
    },
  },
}));