import { LogoWrapper } from './styled';
import logo from '../../assets/icons/logo.svg';

function Logo() {
  return (
    <LogoWrapper>
      <img src={logo} alt="logo" />
    </LogoWrapper>
  );
}

export default Logo;
