import { IMove } from '@models/move';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { IGetMyMoves, IJobState } from '@store/features/move/move.types';

const initialState: IJobState = {
  jobsData: {} as IGetMyMoves['response']
};

export const jobSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    setJobsList: (state, { payload }: PayloadAction<IGetMyMoves['response']>) => {
      state.jobsData = payload;
    },
  }
});

export const { setJobsList } = jobSlice.actions;
