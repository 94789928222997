import { createApi } from '@reduxjs/toolkit/query/react';
import { LoginFormValues } from '../../../content/pages/Login';
import { buildBaseQuery } from '../../baseQuery';
import {
  IProfile,
  ISetPasswordRequest,
  ISetPasswordRequestBase,
} from './auth.types';
import { ResetPasswordFormValues } from '../../../content/pages/ResetPassword';
import { SetNewPasswordFormValues } from '../../../content/pages/SetNewPassword';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: buildBaseQuery(''),
  keepUnusedDataFor: 0,
  tagTypes: ['profile'],
  endpoints: builder => ({
    // getProfile: builder.query<IProfile, void>({
    //   query: () => '/partner/profile',
    //   providesTags: ['profile'],
    // }),
    login: builder.mutation<
      { accessToken: string; refreshToken: string },
      LoginFormValues
    >({
      query: body => {
        return {
          url: '/auth/login',
          body: { ...body, role: 'partner' },
          method: 'POST',
        };
      },
    }),

    setPassword: builder.mutation<void, ISetPasswordRequest>({
      query: body => {
        return {
          url: '/partners/verify',
          body,
          method: 'PUT',
        };
      },
    }),

    updatePassword: builder.mutation<void, ISetPasswordRequestBase>({
      query: body => {
        return {
          url: '/auth/password',
          body,
          method: 'PATCH',
        };
      },
    }),
    resetPassword: builder.mutation<void, ResetPasswordFormValues>({
      query: body => {
        return {
          url: '/auth/reset-password',
          body: {
            ...body,
            role: 'partner',
            redirectUri: `${window.location.origin}/set-new-password`,
          },
          method: 'POST',
        };
      },
    }),
    setNewPassword: builder.mutation<void, SetNewPasswordFormValues>({
      query: body => {
        return {
          url: '/auth/password',
          body,
          method: 'PUT',
        };
      },
    }),
    // refreshToken: builder.mutation<
    //   { accessToken: string; refreshToken: string },
    //   { refreshToken: string }
    // >({
    //   query: body => ({
    //     url: '/auth/refresh-token',
    //     body,
    //     method: 'POST',
    //   }),
    // }),
  }),
});

export const {
  // useGetProfileQuery,
  // useLazyGetProfileQuery,
  useLoginMutation,
  useSetPasswordMutation,
  useUpdatePasswordMutation,
  useResetPasswordMutation,
  useSetNewPasswordMutation,
} = authApi;
