import { css } from '@mui/material/styles';

import { FontFamilyName, ThemeFont } from '../../constants/fonts';

import PlainBoldTtf from './Plain-Bold.ttf';
import PlainBoldWoff2 from './Plain-Bold.woff2';
import PlainBoldWoff from './Plain-Bold.woff';
import PlainBoldItalicTtf from './Plain-BoldItalic.ttf';
import PlainBoldItalicWoff2 from './Plain-BoldItalic.woff2';
import PlainLight from './Plain-Light.ttf';
import PlainLightItalic from './Plain-LightItalic.ttf';

const PlainFont: ThemeFont = {
  fontFamily: [
    FontFamilyName.PlainLight,
    FontFamilyName.PlainBold,
    FontFamilyName.PlainBoldItalic,
  ],
  fontFaces: css`
    @font-face {
      font-family: ${FontFamilyName.PlainBold};
      font-style: normal;
      font-display: auto;
      src: local(${FontFamilyName.PlainBold}),
        url(${PlainBoldWoff2}) format('woff2'),
        url(${PlainBoldWoff}) format('woff'),
        url(${PlainBoldTtf}) format('truetype');
    }

    @font-face {
      font-family: ${FontFamilyName.PlainBoldItalic};
      font-style: normal;
      font-display: auto;
      src: local(${FontFamilyName.PlainBoldItalic}),
        url(${PlainBoldItalicWoff2}) format('woff2'),
        url(${PlainBoldWoff}) format('woff'),
        url(${PlainBoldItalicTtf}) format('truetype');
    }

    @font-face {
      font-family: ${FontFamilyName.PlainLight};
      font-style: normal;
      font-display: auto;
      src: local(${FontFamilyName.PlainLight}),
        url(${PlainLight}) format('truetype');
    }

    @font-face {
      font-family: ${FontFamilyName.PlainLightItalic};
      font-style: normal;
      font-display: auto;
      src: local(${FontFamilyName.PlainLightItalic}),
        url(${PlainLightItalic}) format('truetype');
    }
  `,
};

export default PlainFont;
