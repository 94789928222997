import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from '../../baseQuery';
import { ICreateCardRequest, IGetCardsResponse } from './card.types';
import { baseQueryWithReAuth } from '@store/baseQueryWithReAuth';

export const cardApi = createApi({
  reducerPath: 'cardApi',
  baseQuery: baseQueryWithReAuth('partner/cards'),
  keepUnusedDataFor: 0,
  endpoints: builder => ({
    getCards: builder.query<IGetCardsResponse, void>({
      query: () => ({
        url: '',
      }),
    }),
    createSetupIntent: builder.mutation<{ client_secret: string }, void>({
      query: () => ({
        url: `/setup-intent`,
        method: 'POST',
      }),
    }),
    createCard: builder.mutation<void, ICreateCardRequest>({
      query: body => ({
        url: `/`,
        method: 'POST',
        body,
      }),
    }),
    makeCardDefault: builder.mutation<void, string>({
      query: cardId => ({
        url: `${cardId}/default`,
        method: 'PATCH',
      }),
    }),
    deleteCard: builder.mutation<void, string>({
      query: cardId => ({
        url: cardId,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetCardsQuery,
  useCreateCardMutation,
  useMakeCardDefaultMutation,
  useDeleteCardMutation,
  useCreateSetupIntentMutation,
} = cardApi;
