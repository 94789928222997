import { Fragment, useState } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { FieldPath, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import ErrorMessage from '@components/ErrorMessage';
import { StyledErrorMessage } from '@components/ErrorMessage/styled';

import { ReactComponent as CloseIcon } from '@assets/icons/popup-close-icon.svg';
import contactUs1 from '@assets/icons/contactUs1.svg';
import contactUs2 from '@assets/icons/contactUs2.svg';
import { ContactUsFormSchema } from './schema';
import { useContactUsMutation } from '@store/features/app/app.api';
import {
  StyledDialogContent,
  StyledIconButton,
  StyledLeftLogo,
  StyledModal,
  StyledModalTitle,
  StyledRightLogo,
  StyledSuccessMessage,
  StyledTextArea,
  StyledTextField,
  StyledPhoneNumber,
} from './styled';
import PhoneField from '@components/PhoneField';
import { COUNTRY_CODE } from '@config/constants';

interface ContactUsPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface IContactUsFormValues {
  name: string;
  email: string;
  phone: string;
  message: string;
}

export interface State {
  id: number;
  code: string;
  name: string;
}

interface Field {
  name: FieldPath<IContactUsFormValues>;
  placeholder: string;
}

const fields: Field[] = [
  {
    name: 'name',
    placeholder: 'Your name',
  },
  {
    name: 'email',
    placeholder: 'Your email',
  },
  {
    name: 'phone',
    placeholder: 'Your phone number',
  },
];

const ContactUsPopup = ({ isOpen, onClose }: ContactUsPopupProps) => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [contactUs, { isLoading }] = useContactUsMutation();

  const clearMessages = () => {
    setMessage('');
    setError('');
  };

  const handleLogin: SubmitHandler<IContactUsFormValues> = async data => {
    clearMessages();

    try {
      await contactUs({
        ...data,
        phone: `${COUNTRY_CODE}${data.phone}`,
      }).unwrap();

      setMessage('Your message has been sent');

      reset();
    } catch (e) {
      if (e?.data?.errors?.phone) {
        const message = e?.data?.errors?.phone[0];
        setError(message);
        return;
      }

      setError('Something went wrong, try again');
    }
  };

  const {
    control,
    reset,
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<IContactUsFormValues>({
    resolver: yupResolver(ContactUsFormSchema),
  });

  const handleClose = () => {
    onClose();
    clearErrors();
    reset();
  };

  return (
    <StyledModal keepMounted open={isOpen} onClose={handleClose}>
      <StyledDialogContent>
        <DialogTitle sx={{ padding: 0 }}>
          <StyledIconButton
            aria-label="close"
            onTouchMove={handleClose}
            onClick={handleClose}>
            <CloseIcon />
          </StyledIconButton>
        </DialogTitle>
        <StyledModalTitle variant="h1" textAlign="center">
          Contact Us
        </StyledModalTitle>
        <form onSubmit={handleSubmit(handleLogin, clearMessages)}>
          <Stack alignItems="center">
            {fields.map(field => {
              if (field.name === 'phone') {
                return (
                  <Fragment key={field.name}>
                    <PhoneField control={control} placeholder={'Your phone'}>
                      <StyledPhoneNumber />
                    </PhoneField>
                    {errors?.phone?.message && (
                      <StyledErrorMessage>
                        {errors?.phone?.message}
                      </StyledErrorMessage>
                    )}
                  </Fragment>
                );
              }

              return (
                <Fragment key={field.name}>
                  <StyledTextField
                    placeholder={field.placeholder}
                    variant="outlined"
                    type="text"
                    fullWidth
                    {...register(field.name)}
                  />
                  {errors[field.name] && (
                    <ErrorMessage name={field.name} errors={errors} />
                  )}
                </Fragment>
              );
            })}
            <StyledTextArea
              placeholder="Your message"
              {...register('message')}
            />
            {errors.message && <ErrorMessage name="message" errors={errors} />}

            {!!error && <StyledErrorMessage>{error}</StyledErrorMessage>}

            {!!message && (
              <StyledSuccessMessage>{message}</StyledSuccessMessage>
            )}

            <Box marginTop={2}>
              <Button variant="contained" type="submit" disabled={isLoading}>
                Send
              </Button>
            </Box>
          </Stack>
        </form>

        <StyledLeftLogo src={contactUs1} />
        <StyledRightLogo src={contactUs2} />
      </StyledDialogContent>
    </StyledModal>
  );
};

export default ContactUsPopup;
