import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AuthState, IProfile } from './auth.types';
import { AuthStorageKeys } from '@config/constants';

const initialState: AuthState = {
  accessToken: localStorage.getItem(AuthStorageKeys.ACCESS_TOKEN) || '',
  refreshToken: localStorage.getItem(AuthStorageKeys.REFRESH_TOKEN) || '',
  profile: JSON.parse(localStorage.getItem(AuthStorageKeys.USER)) || {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (
      state,
      { payload }: PayloadAction<{ accessToken: string; refreshToken: string }>, // Updated payload type
    ) => {
      localStorage.setItem(AuthStorageKeys.ACCESS_TOKEN, payload.accessToken);
      localStorage.setItem(AuthStorageKeys.REFRESH_TOKEN, payload.refreshToken);
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
    },
    setUser: (state, { payload }: PayloadAction<IProfile>) => {
      localStorage.setItem(AuthStorageKeys.USER, JSON.stringify(payload));
      state.profile = payload;
    },
    logout: state => {
      localStorage.removeItem(AuthStorageKeys.ACCESS_TOKEN);
      localStorage.removeItem(AuthStorageKeys.REFRESH_TOKEN);
      localStorage.removeItem(AuthStorageKeys.USER);
      state.accessToken = '';
      state.refreshToken = '';
      // @ts-ignore
      state.profile = {};
    },
  },
});

export const { setToken, setUser, logout } = authSlice.actions;

export default authSlice.reducer;
