import { FC, ReactNode, useEffect } from 'react';
import { alpha, Box, lighten, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';
import { useAppDispatch, useAppSelector } from '../../store';
import SuspenseLoader from '../../components/SuspenseLoader';
import {
  setIsMaxCubFitPopupOpen,
  setIsWelcomeBoardPopupOpen,
} from '@store/features/app/app.slice';
import WelcomeJobBoardPopup from '@components/popups/WelcomeJobBoardPopup';
import MaximumCubicFeet from '@components/popups/MaximumCubicFeet';
import { useAuthAndNavigation } from '../hooks/useAuthAndNavigation';
import COLORS from '@utils/colors';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const user = useAppSelector(({ auth }) => auth.profile);
  const { isWelcomeBoardPopupOpen, isMaxCubFitPopupOpen } = useAppSelector(
    state => state.app,
  );

  const { isLoading } = useAuthAndNavigation();

  useEffect(() => {
    dispatch(setIsWelcomeBoardPopupOpen(true));
  }, []);

  if (!user || isLoading) {
    return <SuspenseLoader />;
  }

  return (
    <>
      <MaximumCubicFeet
        isOpen={isMaxCubFitPopupOpen}
        onClose={() => {
          dispatch(setIsMaxCubFitPopupOpen(false));
        }}
      />

      <WelcomeJobBoardPopup
        isOpen={isWelcomeBoardPopupOpen}
        onClose={() => dispatch(setIsWelcomeBoardPopupOpen(false))}
      />
      <Box
        sx={{
          flex: 1,
          height: '100%',
          backgroundColor: COLORS.grey[900],

          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `10px`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                    lighten(theme.colors.primary.main, 0.7),
                    0.15,
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                    theme.colors.alpha.black[100],
                    0.1,
                  )}, 0px 5px 12px -4px ${alpha(
                    theme.colors.alpha.black[100],
                    0.05,
                  )}`,
          },
        }}>
        <Header />
        <Sidebar />
        <Box
          className="11111111111111"
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `${theme.header.height}`,

            '.MuiContainer-root': {
              ml: 0,
              mr: 'unset',
            },
            [theme.breakpoints.up('lg')]: {
              ml: `${theme.sidebar.width}`,
            },
          }}>
          <Box display="block">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
