import { ThemeOptions } from '@mui/material/styles';

import MoretFont from '@assets/Moret';
import PlainFont from '@assets/Plain';
import COLORS from '@utils/colors';

export const typography: ThemeOptions['typography'] = (/* theme */) => ({
  fontWeightBold: 700,
  fontWeightMedium: 500,
  fontWeightRegular: 400,
  fontWeightLight: 300,
  allVariants: {
    color: COLORS.darkBlue,
  },
  fontFamily: `"Inter", ${PlainFont.fontFamily.join(
    ', ',
  )}, ${MoretFont.fontFamily.join(', ')}, sans-serif`,
});
