import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@store/baseQueryWithReAuth';

export const subscriptionApi = createApi({
  reducerPath: 'subscriptionApi',
  baseQuery: baseQueryWithReAuth('partner'),
  keepUnusedDataFor: 0,
  endpoints: builder => ({
    cancelSubscription: builder.mutation<void, void>({
      query: () => {
        return {
          url: '/subscriptions',
          method: 'DELETE',
          body: {
            atEnd: true,
          },
        };
      },
    }),
    resubscribe: builder.mutation<void, void>({
      query: body => {
        return {
          url: '/subscriptions',
          body,
          method: 'POST',
        };
      },
    }),
    dontCancel: builder.mutation<void, void>({
      query: body => {
        return {
          url: '/subscriptions',
          body,
          method: 'PATCH',
        };
      },
    }),
  }),
});

export const {
  useCancelSubscriptionMutation,
  useResubscribeMutation,
  useDontCancelMutation,
} = subscriptionApi;
