import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from '../../baseQuery';
import { IMove, MoveStatus } from '@models/move';
import {
  IAvailableMovesRequest,
  IAvailableMovesResponse,
  IGetLoadBoard,
  IGetMoveByIdResponse,
  IGetMyMoves,
} from '@store/features/move/move.types';
import { countOffsetForRequest } from './move.helpers';
import { baseQueryWithReAuth } from '@store/baseQueryWithReAuth';
import { IProfile } from '@store/features/auth/auth.types';

export const moveApi = createApi({
  reducerPath: 'movesApi',
  baseQuery: baseQueryWithReAuth(''),
  keepUnusedDataFor: 0,
  tagTypes: ['profile'],
  endpoints: builder => ({
    getProfile: builder.query<IProfile, void>({
      query: () => '/partner/profile',
      providesTags: ['profile'],
    }),
    getLeadById: builder.query<IMove, { moveId: number }>({
      query: ({ moveId }) => ({
        url: `partner/moves/${moveId}`,
      }),
    }),
    getAvailableMoves: builder.query<
      IAvailableMovesResponse,
      IAvailableMovesRequest
    >({
      query: params => ({
        url: 'partner/available-moves',
        params: countOffsetForRequest(params),
      }),
    }),
    getLeads: builder.query<IGetMyMoves['response'], IGetMyMoves['request']>({
      query: params => ({
        url: 'partner/leads',
        params: countOffsetForRequest(params),
      }),
    }),
    getJobs: builder.query<IGetMyMoves['response'], IGetMyMoves['request']>({
      query: params => ({
        url: 'partner/jobs',
        params: countOffsetForRequest(params),
      }),
    }),
    createBid: builder.mutation<
      IGetMyMoves['response'],
      {
        moveId: number;
        totalPrice: number;
        cubicFeetPrice: number;
      }
    >({
      query: ({ moveId, ...rest }) => ({
        url: `/partner/moves/${moveId}/bids`,
        method: 'POST',
        body: rest,
      }),
    }),
    // ########################################
    createMoveLead: builder.mutation<void, { moveId: number }>({
      query: ({ moveId }) => ({
        url: `/partner/moves/${moveId}/leads`,
        method: 'POST',
      }),
    }),
    getLoadBoard: builder.query<
      IGetLoadBoard['response'],
      IGetLoadBoard['request']
    >({
      query: params => ({
        url: 'moves/loadboard',
        params: countOffsetForRequest(params),
      }),
      keepUnusedDataFor: 0,
    }),
    bookMove: builder.mutation<void, { moveId: string; cardId: string }>({
      query: ({ moveId, cardId }) => ({
        url: `moves/${moveId}/book`,
        method: 'POST',
        body: {
          cardId,
        },
      }),
    }),
    updateMoveStatus: builder.mutation<
      void,
      {
        moveId: string;
        bookId: string;
        status: MoveStatus;
        images?: string[];
        bill?: string;
      }
    >({
      query: ({ moveId, bookId, status, images, bill }) => ({
        url: `moves/${moveId}/book`,
        method: 'PUT',
        body: {
          bookId,
          status,
          images,
          bill,
        },
      }),
    }),
    downloadMoves: builder.query<{ type: string; objectUrl: string }, any>({
      query: params => ({
        url: `moves/partners`,
        params: {
          ...params,
          download: true,
        },
        responseHandler: response =>
          response.blob().then(blob => ({
            type: blob.type,
            objectUrl: URL.createObjectURL(blob),
          })),
      }),
    }),
    downloadMove: builder.query<{ type: string; objectUrl: string }, string>({
      query: moveId => ({
        url: `/${moveId}/download`,
        responseHandler: response =>
          response.blob().then(blob => ({
            type: blob.type,
            objectUrl: URL.createObjectURL(blob),
          })),
      }),
    }),
    getMyMoves: builder.query<IGetMyMoves['response'], IGetMyMoves['request']>({
      query: params => ({
        url: 'moves/partners',
        params: countOffsetForRequest(params),
      }),
    }),
    getMove: builder.query<IGetMoveByIdResponse, string>({
      query: id => `moves/${id}/info`,
    }),
  }),
});

export const {
  useGetProfileQuery,
  useLazyGetProfileQuery,
  useCreateMoveLeadMutation,
  useLazyGetAvailableMovesQuery,
  useLazyGetLeadByIdQuery,
  useGetLoadBoardQuery,
  useBookMoveMutation,
  useGetMyMovesQuery,
  useGetMoveQuery,
  useLazyDownloadMoveQuery,
  useLazyDownloadMovesQuery,
  useUpdateMoveStatusMutation,
  useLazyGetJobsQuery,
  useLazyGetLeadsQuery,
  useCreateBidMutation,
} = moveApi;
