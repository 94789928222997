import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = Component => props =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger')),
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions')),
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile')),
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings')),
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons')),
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals')),
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions')),
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges')),
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips')),
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars')),
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404')),
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500')),
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon')),
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance')),
);

const Login = Loader(lazy(() => import('src/content/pages/Login')));
const ResetPassword = Loader(
  lazy(() => import('src/content/pages/ResetPassword')),
);
const SetNewPassword = Loader(
  lazy(() => import('src/content/pages/SetNewPassword')),
);
const ActivateAccount = Loader(
  lazy(() => import('src/content/pages/ActivateAccount')),
);

const LoadBoard = Loader(lazy(() => import('src/content/pages/LoadBoard')));
const Move = Loader(lazy(() => import('src/content/pages/Move')));

const MyCards = Loader(lazy(() => import('src/content/pages/MyCards')));

const MyMoves = Loader(lazy(() => import('src/content/pages/MyMoves')));
const MyMove = Loader(lazy(() => import('src/content/pages/MyMove')));
const JobBoards = Loader(lazy(() => import('src/content/pages/JobBoards')));
const MyLeads = Loader(lazy(() => import('src/content/pages/MyLeads')));
const MyProfile = Loader(lazy(() => import('src/content/pages/MyProfile')));

const CompletedMoves = Loader(
  lazy(() => import('src/content/pages/CompletedMoves')),
);

const LoginWithToken = Loader(
  lazy(() => import('src/content/pages/LoginWithToken')),
);
// const Subscription = Loader(
//   lazy(() => import('src/content/pages/Subscription'))
// );
const ServiceArea = Loader(lazy(() => import('src/content/pages/ServiceArea')));

const Receipts = Loader(lazy(() => import('src/content/pages/Receipts')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      // {
      //   path: '/moves/:id/view',
      //   element: <Move />
      // },
      // {
      //   path: '/my-moves/:state',
      //   element: <MyMoves />
      // },
      // {
      //   path: '/completed-moves',
      //   element: <CompletedMoves />
      // },
      // {
      //   path: '/my-moves/:id/view',
      //   element: <MyMove />
      // },
      {
        path: '/',
        element: <Navigate to="/service-area" replace />,
      },
      {
        path: '/my-cards',
        element: <MyCards />,
      },
      {
        path: '/lead-board',
        element: <JobBoards />,
      },
      {
        path: '/my-leads',
        element: <MyLeads />,
      },
      {
        path: '/profile',
        element: <MyProfile />,
      },
      {
        path: '/receipts',
        element: <Receipts />,
      },
      // {
      //   path: '/subscription',
      //   element: <Subscription />
      // },
      {
        path: '/service-area',
        element: <ServiceArea />,
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: 'activate-account',
    element: <ActivateAccount />,
  },
  {
    path: 'login-with-token',
    element: <LoginWithToken />,
  },
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      {
        path: 'set-new-password',
        element: <SetNewPassword />,
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />,
          },
          {
            path: '404',
            element: <Status404 />,
          },
          {
            path: '500',
            element: <Status500 />,
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />,
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />,
          },
        ],
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="crypto" replace />,
      },
      {
        path: 'crypto',
        element: <Crypto />,
      },
      {
        path: 'messenger',
        element: <Messenger />,
      },
    ],
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />,
      },
      {
        path: 'transactions',
        element: <Transactions />,
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />,
          },
          {
            path: 'details',
            element: <UserProfile />,
          },
          {
            path: 'settings',
            element: <UserSettings />,
          },
        ],
      },
    ],
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />,
      },
      {
        path: 'buttons',
        element: <Buttons />,
      },
      {
        path: 'modals',
        element: <Modals />,
      },
      {
        path: 'accordions',
        element: <Accordions />,
      },
      {
        path: 'tabs',
        element: <Tabs />,
      },
      {
        path: 'badges',
        element: <Badges />,
      },
      {
        path: 'tooltips',
        element: <Tooltips />,
      },
      {
        path: 'avatars',
        element: <Avatars />,
      },
      {
        path: 'cards',
        element: <Cards />,
      },
      {
        path: 'forms',
        element: <Forms />,
      },
    ],
  },
];

export default routes;
